'use client';

import Script from 'next/script';

import { PUBLIC_DIRECTORY } from '@/src/constants';

export default function OpinionLab({ locale }: { locale: string }) {
    return (
        <>
            <Script
                id="OpinionLab"
                src={
                    locale === 'fr-ca'
                        ? `${PUBLIC_DIRECTORY}/opinionlab_ca.min.js`
                        : `${PUBLIC_DIRECTORY}/opinionlab_us.min.js`
                }
            />
        </>
    );
}
